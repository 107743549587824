import "./Footer.css";


export function Footer() {
    return <footer className="footer">
    <div className="footer-links">
      <a href="https://app.fantasy-calendar.com/calendars/ac78c08c5c9afd26255cde628f85e16e" target="_blank" rel="noreferrer">Telos Calender</a>
      <a href="https://drive.google.com/drive/u/0/folders/1_JfStZnduxQ8EVM1M5ZhaK-xCYtWkF5G" target="_blank" rel="noreferrer">DnD Ultima App Private Version</a>
    </div>
  </footer>
}