export const sinocardBorderData: number[][] = [
  [-3040, -10],
  [-3060, 5],
  [-3080, 5],
  [-3100, 5],
  [-3110, 25],
  [-3140, 30],
  [-3155, 50],
  [-3160, 75],
  [-3180, 85],
  [-3200, 80],
  [-3250, 55],
  [-3270, 60],
  [-3320, 55],
  [-3335, 30],
  [-3390, 15],
  [-3425, -10],
  [-3465, -20],
  [-3490, -50],
  [-3490, -135],
  [-3530, -180],
  [-3565, -170],
  [-3620, -170],
  [-3670, -200],
  [-3700, -190],
  [-3740, -205],
  [-3790, -210],
  [-3850, -250],
  [-3900, -290],
  [-3925, -290],
  [-3950, -280],
  [-3980, -305],
  [-4000, -300],
  [-4020, -300],
  [-4055, -275],
  [-4100, -275],
  [-4125, -250],
  [-4170, -245],
  [-4200, -230],
  [-4285, -205],
  [-4310, -140],
  [-4335, -120],
  [-4400, -30],
  [-4535, 50],
  [-4535, 75],
  [-4580, 120],
  [-4635, 145],
  [-4700, 150],
  [-4760, 160],
  [-4770, 195],
  [-4790, 195],
  [-4800, 215],
  [-4805, 290],
  [-4825, 295],
  [-4830, 285],
  [-4860, 285],
  [-4860, 320],
  [-4890, 350],
  [-4960, 320],
  [-4960, 435],
  [-4940, 435],
  [-4900, 450],
  [-4830, 430],
  [-4830, 545],
  [-4790, 600],
  [-4720, 670],
  [-4680, 690],
  [-4600, 740],
  [-4560, 730],
  [-4520, 740],
  [-4475, 725],
  [-4450, 750],
  [-4380, 740],
  [-4300, 800],
  [-4290, 845],
  [-4200, 860],
  [-4100, 900],
  [-4000, 930],
  [-3900, 990],
  [-3795, 1030],
  [-3760, 1020],
  [-3700, 1015],
  [-3670, 1000],
  [-3620, 920],
  [-3600, 885],
  [-3505, 865],
  [-3450, 820],
  [-3420, 810],
  [-3365, 780],
  [-3340, 790],
  [-3295, 790],
  [-3230, 720],
  [-3195, 655],
  [-3190, 590],
  [-3175, 575],
  [-3175, 555],
  [-3185, 545],
  [-3185, 505],
  [-3220, 480],
  [-3245, 480],
  [-3245, 470],
  [-3200, 415],
  [-3200, 405],
  [-3190, 405],
  [-3190, 455],
  [-3180, 495],
  [-3160, 475],
  [-3130, 450],
  [-3085, 450],
  [-3060, 460],
  [-3035, 475],
  [-3040, -10],
];
