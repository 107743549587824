export const borrasBorderData: number[][] = [
  [3990, 0],
  [3960, 50],
  [3900, 140],
  [3840, 160],
  [3800, 200],
  [3760, 255],
  [3700, 255],
  [3650, 280],
  [3600, 300],
  [3550, 350],
  [3500, 350],
  [3400, 370],
  [3350, 400],
  [3325, 470],
  [3300, 430],
  [3250, 410],
  [3200, 420],
  [3150, 400],
  [3100, 410],
  [3050, 415],
  [3000, 385],
  [2950, 380],
  [2900, 400],
  [2850, 415],
  [2800, 400],
  [2750, 385],
  [2700, 375],
  [2600, 345],
  [2500, 250],
  [2400, 260],
  [2450, 200],
  [2430, 150],
  [2400, 130],
  [2350, 140],
  [2350, 0],
  [2350, -200],
  [2350, -500],
  [2350, -800],
  [2400, -1000],
  [2350, -1200],
  [2330, -1400],
  [2340, -1470],
  [2400, -1410],
  [2500, -1395],
  [2600, -1470],
  [2700, -1550],
  [2800, -1590],
  [2870, -1600],
  [2950, -1685],
  [2970, -1780],
  [2990, -1850],
  [2970, -1930],
  [3000, -1970],
  [3020, -2030],
  [3020, -2090],
  [3070, -2120],
  [3030, -2160],
  [3030, -2370],
  [3110, -2430],
  [3110, -2490],
  [3160, -2555],
  [3220, -2600],
  [3300, -2600],
  [3370, -2600],
  [3425, -2580],
  [3520, -2620],
  [3590, -2640],
  [3650, -2630],
  [3750, -2580],
  [3770, -2560],
  [3790, -2560],
  [3830, -2580],
  [3850, -2610],
  [3920, -2630],
  [3930, -2580],
  [3960, -2550],
  [4050, -2565],
  [4100, -2570],
  [4170, -2510],
  [4200, -2450],
  [4250, -2400],
  [4260, -2355],
  [4290, -2345],
  [4290, -2285],
  [4340, -2235],
  [4360, -2220],
  [4360, -2050],
  [4380, -2015],
  [4440, -2010],
  [4450, -1980],
  [4420, -1980],
  [4390, -1960],
  [4360, -1910],
  [4360, -1880],
  [4390, -1880],
  [4365, -1800],
  [4380, -1760],
  [4420, -1725],
  [4360, -1730],
  [4350, -1700],
  [4390, -1660],
  [4355, -1620],
  [4365, -1580],
  [4355, -1550],
  [4380, -1510],
  [4355, -1470],
  [4330, -1410],
  [4355, -1390],
  [4340, -1330],
  [4350, -1290],
  [4400, -1270],
  [4380, -1240],
  [4350, -1200],
  [4375, -1160],
  [4340, -1115],
  [4310, -1060],
  [4290, -1010],
  [4235, -970],
  [4235, -870],
  [4250, -830],
  [4230, -800],
  [4240, -745],
  [4200, -690],
  [4180, -625],
  [4165, -550],
  [4180, -470],
  [4140, -430],
  [4120, -380],
  [4100, -340],
  [4130, -300],
  [4100, -250],
  [4110, -220],
  [4080, -200],
  [4050, -130],
  [3990, 0],
];
