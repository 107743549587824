export const recapData: { title: string; recap: string }[] = [
  {
    title: "Stone Garden Episode 1 - Widerstand",
    recap:
      "Die Stadt Capsan befindet sich in mitten der Festlichkeiten für Feiertag des Kaisers, als mehrere Explosionen die Stadt in Alarmbereitschaft versetzten. Die 4 Magiertürme aktivierten sich und projezierten die antike Schutzbarriere zu allen überraschen, welche eigentlich seit langer Zeit nicht mehr aktiv war. Dämonische Kreaturen kamenn aus den Türmen und stürtzten sich auf die Bewohner der Stadt. Vier fähig aussehende Individuuen wurden von General Laxus beauftragt zum Turm von Evius zu gehen und den Barrierenstein dort zu deaktivieren. Ben, Horizon, Lucardis und Ziora machten ich auf den Weg, sie besiegten einige der Kreaturen und fanden ein Portal welches die Dämonen hier her brachten im oberen Teil des Turmes. Im Keller des Trumes, wo sie der Barrierenstein befindet, entdeckten sie das dieser mit Hilfe eines Manatrankes aktiviert wurde. Dies deutete darauf hin das der Widerstand, eine Untergrundopposition, diesen Angriff zu verantworten hat.",
  },
  {
    title: "Stone Garden Episode 2 - Unschuld",
    recap:
      "Nach dem sich die Lage wieder beruhigt hat, gingen die vier vom Envius Turm zum Stadtzentrum um sich dort mit General Laxus zu treffen. Als sie ankamen haben die Soldaten des Imperiums den Zentralen Platz bereits abgesperrt um dort einige Mitglieder des Widerstandes fest zu halten. Verdächtige welche nicht kooperierten wurden auf der Stelle von Dracon Marek exekutiert, ein Mann names David, behauptet dass er erpresst worden ist und seine Frau und sein Kind entführt worden sind. Ben, Horizon , Lucardis und Ziora nahmen sich der Aufgabe an die Unschuld von David zu beweiseen. Sie gingen Richtung Süden in den Libera Wald um einige der geflüchteten Dämonen zu finden, welche sie auch in einer verlassenen Hütte im Wald fanden. Dort entdeckten sie einige Dokumente und Pläne für den Angriff auf Capsan, sowie einige Magische Gegenständee. Zurück in Capsan konnten sie mit den Indizien die Unschuld von David beweisen.",
  },
  {
    title: "Stone Garden Episode 3 - Drinks, K und Reisen",
    recap:
      "Nach den jüngsten Ereignissen gönnt sich die Gruppe einen Tag in Capsan. Vorräte und neue Waffen werden für die nächste Reise, Richtung Vectis und Aramore, besorgt. Den Abend lassen sie im Himmlischen Imp ausklingen, einer Taverne die von einer interessaten Frau names Sandra geführt wird. 5 Reisetage später kommen sie in der Stadt Vectis an wo Ben seinen Begleiter K neu in einer anderen Form neu beschwört, nach dem dieser im Kampf gefallen war.",
  },
  {
    title: "Stone Garden Episode 4 - Regen in Sicht",
    recap:
      "Ziora spürt das der Blaue Wald, welcher in westlich von Vectis liegt, in Gefahr zu sein scheint. Im Wald finden sie ein Baumhaus welches sie untersuchen, doch werden dabei von Skeletten und einem Slaadi(Frosch Dämonen) angeriffen. Ein blaues Tiefling Mädchen namens Rain kommt ihnen zur Unterstützung und gemeinsam schaffen sie es die Angreifer zu besiegen. Rain erzählt davon das seit einiger Zeit diese Kreaturen vermehrt im Wald auftauchen und etwas den Schutzpatron des Waldes schwächt, den Sonnenbaum Cohan. Gemeinsam machen sie sich zu Cohan auf den Weg doch der Eingang zu seinem Untergrund Labyrinth wird bereits von weiter Monstern bewacht, in einem geschickten Manöver überwältigen sie die Gegner und gelangen in das Labyrinth. Im Untergrund vor dem Eingang einer Krypta macht Ben sich unsichtbar um diese zu untersuchen und trifft auf einen Mann in einem schwarzen Umhang, welcher sich in der Krypta umschaut und dann plötzlich durch ein magisches Portal verschwindet. Kurz darauf stehen einige Skelette aus ihren Gräbern, doch Ben kann diese anscheind beeinflussen und entgeht einem Kampf. ",
  },
  {
    title: "Stone Garden Episode 5 - Archfey Cohan",
    recap:
      "Rain führt die Gruppe tiefer ins Labyrinth hinein und gelangen zu Cohans Kammer. Cohan erscheint in Form einer Elfisch aussehenden Personen, denn sein eigentlicher Körper bildet nun den Sonnenbaum, wie er ihnen erzählt. Der Prinz der Leere versucht an das, durch ihn versiegelte Artefakt von Atropus, zu kommen. Während der Unterhaltung wird das Versiegelungstor attackiert und er erschafft der Gruppe einen direkt Weg dort hin, während Cohan sich um die Slaadi Armee kümmert. Ein Kampf bricht aus gegen den Prinzen, den Mann den Ben vorher hier schon begegnet ist. Das Tor wurde zerstört, jedoch war der Prinz nicht mehr in der Lage sich das Artefakt zu sichern und floh. Rain nahm das Artefakt, ein Handschuh, an sich und macht sich auf den Weg Richtung Norden um es beim Sonnenbaum im Nachtwald zu verstecken. ",
  },
  {
    title: "Stone Garden Episode 6 - Im Goldenen Libram",
    recap:
      "Auf dem Weg Richttung Aramore, macht die Gruppe halt beim Goldenen Libram. Geführt wird die Taverne von Silas Blanc, einem Ex-Kampfmagier des Imperiums. Nach einer Unterhaltung mit Ben, erzählt er ihnen das er noch Nachtschwingenfedern braucht. Die Kreaturen leben am Füße des Drachenrücken Gebirges. Bei den Toren Aramores angekommen müssen sie feststellen das der Zutritt, aufgrund eines Diebstahls nicht gestattet ist. Von den Wachen erfahren sie das eine Truhe abhanden gekommen ist und die Verdächtigen wohl Richtung Drachenrücken geflohen sind. Also machten sie sich auf den Weg zum Drachenrücken.",
  },
  {
    title: "Stone Garden Episode 7 - Moskitos und Harpyien",
    recap:
      "Auf dem Weg zum Drachenrücken, muss sich die Gruppe zuerst durch das Waldgebiet schlagen und einen Aufstieg finden. Im Waldgebiet haben sie mit außergewöhnlich großen Moskitoschwärmen zu tun finden und muss sich durch gefährliche Sümpfe navigieren. Sie finden nach einiger Zeit einen Aufsteig und stoßen auf ein Harpyienneest, welches ungewöhnlicher Weise mit magischen Totems versehen ist, was ihnen magische Fähigkeiten gab. Als diese besiegt waren, machten sie sich weiter auf den Weg die Banditen zu suchen. Sie überrumpelten 2 patroliernde Banditen und bekamen die Info über den Eingang zum Iron Dome, dem Versteck.",
  },
  {
    title: "Stone Garden Episode 8 - Iron Dome",
    recap:
      "Die Gruppe brach in das Versteck der Banditen ein und versuchten die gestohlene Kiste des Imperiums zu finden. Sie fanden die Kiste und andere Schätze in einem Raum, doch bevor sie die Kiste borgen konnten erscheinte eine Person in kompletter Plattenrüstung, welche anscheind Magnetische Fähigkeiten besaß und anscheind zum Imperium gehören zu scheint. Die Person drohte der Gruppe ihr nicht im Weg zu stehen und sie ließen sie mit der Kiste gehen und durchsuchte weiter das Versteck. Beim Verlassen des Verstecks ist es mittlerweile Nacht geworden und sie entdeckten am Himmel einen Kampf zwischen einer Nachtschwinge und einem Roc, sie versuchten die Zeit zu nutzen um zum Nest der Nachtschwinge zu gelangen um eine Feder für Silas zu besorgen. Allerdings kam die Nachtschwinge früher zurück als gedacht, schafften es aber knapp mit einer Feder zu fliehen.",
  },
  {
    title: "Stone Garden Episode 9 - Aramore",
    recap:
      "Durch die Nachtschwingenfeder die sie Silas brachten, bekamen sie einige magische Gegenstände von ihm und machten sich nun auf den Weg nach Aramore, welches nun wieder zugänglich sein sollte. Sie können in die Stadt eintreten, nach dem sie registriert worden sind. Horizon geht zur zentralen Poststelle um seinen monatlichen Bericht abzugeben. Kurz darauf wird die Gruppe gebeten zum Hauptquatier der Insomnis Vigilis zu kommen. Dort erwaretet sie Mina Odion, Senatorin und Oberhaupt der Insomnis Vigilis. Sie spricht mit ihenen über die jüngsten Ereignisse im Blauen Wald, über Cohan und den Prinz der Leere und möchte die Gruppe im Dienst der Insomnis Vigilis anheuern. Dies bringt ihnen eine nette Bezahlung und einige Vorteile innerhalb des Imperiums. Nach einer Kurzen besprechung nimmt die Gruppe das Angebot an und verlässt das Hauptquatier. Sie erkunden die Stadt, gehen auf den Bazar, durchs Händlerviertel und finden Bücherladen. Horizon will eine bestimmte Schriftrolle haben, doch diese liegt außerhalb des Budget und der Inhaber Harrion bietet Ihnen an zu einem Mann names Flex zu gehen und statt seiner Willen, eine Schuld bei ihm zu begleichen.",
  },
  {title: "Stone Garden Episode 10 - Im Ghetto wird geflext", recap: "Während Ben und Horizon Besorgungen in der Stadt machen, finden Lucardis und Ziora ein interessantes Anwesen. Ziora verwandelt sich in eine Katze und versucht herraus zu finden was dort vor sich geht. Nach einem Katz und Maus spiel mit den Wachen, schafft Ziora es ein Stück Mineral mit gehen zu lassen, was sich im nachhinein als Aetherium herausstellt. Die Gruppe macht sich auf den Weg in den Süden der Stadt um Flex zu finden, werden auf dem Weg dort hin von Kinder verfolgt. Die Kinder arbeiten für Flex und bringen sie zu ihm. Der rote Dragonbon Flex begrüßt die Gruppe und bietet ihnen an eine neu angekommene Gruppe von Kodan, Eisbär Humanoide, welche neu in der Stadt sind, aus zu kundschaft und ihm zu berichten was sie hier machen."},
  {title: "Stone Garden Episode 11 - Grimmig davon gekommen", recap: "Ben und Horizon machen sich zum Stadtteil in dem sich die Kodan befinden sollen und treffen dort auf einen Kodan namens Grimm, sie versuchen Informationen aus ihm heraus zubekommen. Dieser wird mit der Zeit nur von den beiden genervt doch als er erfährt das Ben ein guter Koch sein soll, heuert er sie an um für ein anstehendes Fest der Kodan das Essen zu machen. Um weiter Informationen zu bekommen infiltierte Ben das Schiff der Kodan, er findet ein paar Pergamentrollen und ein Logbuch. Um von dem Schiff zu entkommen legt er ein kleines Feuer zu Ablenkung. Während dessen trifft Lucardis auf eine andere Kalashtar, welche ihr einiges übers Kalashtar sein erklärt und sich zur ihrem Bund einlädt. Am nächsten Tag berichten sie Flex was sie herraus gefunden haben."},
  {title: "Stone Garden Episode 12 - Türen bedeuten Probleme", recap: "Ben bereitet den rest der Gruppe auf den Auftrag vor und zeigt ihnen wie sie ihm beim Kochen helfen können und arbeiten eine kleine Show aus. Auf dem Weg zu den Kodan sammeln sie noch einen Barden, namens Lance auf. Sie treffen sich mit Grimm, dieser bringt sie zu eine alten Anwesen, wo die Kodan momentan leben. Sie machen wofür sie beauftragt wurden und nutzen die Zeit danach um sich im Anwesen um zu sehen. Ziora findet dabei eine merkwürdig aussehende Tür und berührt den Türgriff. Darauf hin scheint die Zeit für alle aus der Gruppe und Lance still zu stehen und scheinen plötzlich in einer Art anderen Dimension zu sein. Horizon erkennt das es sich um Infernale Zeichen bei der Tür handelt und erinnert sich daran das dieses Anwesen eins einer Tiefling Familie gehörte, der Helder Familie. Die folgt dem Weg durch die Tür hinunter und treffen dort auf Teuflische Kreaturen, Nach einem Kampf mit ihnen flieht eine der Kreaturen durch ein Blut getränkten Brunnen. Sie finden einen Raum in der ein Schlüssel in einer durchsichtigen Box schwebt, nach dem sie mehrere Aktionen in verschiedenen Räumen getätigt haben, konnten sie den Schlüssel aus der Box ergattern."},
  {title: "Stone Garden Episode 13 - Himmlischer Teufel", recap: "Der Weg aus dieser anderen Dimension führt durch eine Tür, welche von einem Knochen Engel bewacht wird. Im Kampf mit der Gruppe spaltete sich der Knochen Engel in einen verfallenen Engel und Teufel auf. Die Gruppe besiegte das Monster und konnte aus der Dimension fliehen. Zurück in der Realität ging das Fest der Kodan ungestört weiter und Ben schaffte es Grim den Anführer mit einem besonderen Alkohol zu benebeln um wertvolle Informationen aus ihm herraus zu bekommen und diese dann Flex zu geben. Grim zeigte Ben das Gestein welches sie transportieren und lagern, aufgrund ihrer antimagischen Fähigkeiten. Dieses Auftrag haben sie von der Saevus Aquidus."},
  {title: "Stone Garden Episode 14 - Mentale und Soziale Herausforderung", recap: "Horizon scheint eine mentale Blockade zu haben und geht daher zur Insomnis Vigilis, welche die Warforged mit erbaut haben. Arcanist Daruis, welche auf Arkane Mentalität spezialisiert ist, kümmert sich um Horizion und scheint die Blockda lösen zu können. Der Rest der Gruppe beschafft eine vielzahl an Informationen über verschiedenste Dinge. Besonders Ben trifft darauf auf den grisgrämigen Bibliothekar Gido. "},
  {title: "Stone Garden Episode 15 - Traum Welt Dal Quor", recap: "Die Gruppe bekommt einen Hinweis auf eine geheime Taverne in der sich lauter fähiger Magier befinden sollen. Von einem kleinen Jungen werden sie zur Taverne geführt für ein entgelt. Im Lausigen Zaubertrank versuchen sie an verschiedene Informationen zu gelangen, doch nerven damit eher das Klientel. Eine Feywirkende Dame names Yesra scheint gefallen an Ben zu finden und kann ihm zum Thema Atropus ein wenig helfen. Lucardis trifft sich mit Violett, einer anderen Kalashtar und erzählt ihr vom normalen Leben der meisten Kalashtar. Am nächsten Tag führt Violett die Gruppe zu ihrem Familien Anwesen der Vac´azla, dort schickt Viktor Vac´azla der Kopf der Familie, Lucardis und die Gruppe auf ihre erste Mission in die Traum Welt, welche Dal Quor genannt wird. Dort muss Lucardis ihren Geist finden und eine Verbindugn mit ihm auf bauen. In der Traum Welt angekommen werden die direkt von Quori Biestern attackiert."},
  {title: "Stone Garden Episode 16 - Im Traum gefangen", recap: "Nach dem sie die Quori Biester besiegt haben, versucht Lucardis den kryptischen Hinweisen ihres Geistes zu folgen und ihn zu finden. Nach einiger verstrichener Zeit finden sie Anhänger des Blinden Wächters, diese erzählen der Gruppe einiges über die Traumwelt und das Lucardis sich dem Blinden Wächter beweisen muss, in dem sie ein Ritual vollführt und einen bösen Quori zu reinigen."},
  {title: "Stone Garden Episode 17 - Der Blinde Wächter", recap: "Die Gruppe begibt sich in ein abgestorbenes Waldgebiet in der Traum Welt und findet dort ein Ritual Altar und lockt nun ein Quori Geist zu sich. Ein schwerer Kampf beginnt für die Gruppe, sie schaffen es die fast Unsterbliche Kreatur zu besiegen und beenden das Ritual. Der Quori bedankt sich bei der Gruppe und wird von ihnen Steve genannt. Steve schließt sich darauf hin dem Blinden Wächter an der in diesem Moment erscheint und Lucardis ihre ersten Kalashtar Fähigkeiten gibt. Die Verbindung zwischen den Beiden ist hergestellt. Der Blinde Wächter bringt die Gruppe aus der Traum Welt zurück. "},
  
  {title: "Stone Garden Episode 18 - Eine Tür schließt, eine Tür öffnet", recap: "Mina Odion wartet am Morgen auf die Gruppe, besonders auf Ziora. Sie hat einen besonderen Auftrag für Ziora welcher mit dem Nachtwald zu tun hat. Ziora nimmt den Auftrag von Mina an und verlässt damit die Gruppe, welche nun erstmal zu 3. weiter unterwegs sein werden. Mina bietet ihnen in Aramore eine eigene Unterkunft an. Die Gruppe reis allerdings demnächst weiter Richtung Esquillin. Sie ergattern noch ihre erste Bag of Holding bei Tias Glitzereien und besorgen sich zwei Drakes bei der Züchterin Enlana."},
  {title: "Stone Garden Episode 19 - Alte Wege, neue Pfade", recap: "Auf ihren beiden neuen Drakes, Lisbeth und Kyrok, macht sich die Gruppe auf den Weg von Aramore nach Vectis. Sie machen nochmal einen Halt beim Goldenen und treffen hinter der Taverne auf eine herunter gekommene Zwergen Dame. Die Zwergin ist der Gruppe erst zurückhalten gegenüber, aber als diese Sie zum Essen einlädt scheint die Freundschaft bereits zu beginnen. Gellea Felsenfaust erzählte der Gruppe das sie gerade aus den Händen von Dragonborn geflüchtet ist, welche sie irgendwo im Gebirge des Drachen Rückens gefangen hielten. Die Gruppe bot Gellea also erstmal an mit ihnen weiter zu Reisen. Sie sprachen noch mit Silas, dieser erzählt ihnen von einem Versteck im Nachtwald, dort hat er ein besonderen magischen Gegenstande versteck, welchen er im Krieg gegen Salona ergattert hat. In Vectis bemerken sie wie sie von einigen Dragonborn misstrauisch beobachtet werden. Als sie einen Blauen Dragonborn, Zinran, fragen was los sei, erklärt er ihnen das Soldaten oft ihre Macht gegen sie Missbrauchen und sie die Gruppe für weitere Soldaten gehalten haben. Ben verspricht Zinran etwas gegen die Situation zu unternehmen, jetzt wo er Kontakt wichtigen Personen aus dem Imperium hat."},
  {title: "Stone Garden Episode 20 - Schwarzmarkt", recap: "Die Gruppe muss an etwas Geld kommen und will einige der Schätze und Materialien verkaufen, welche sie bei den vorherigen Abenteuern gefunden haben. Ben hatte einen Plan, sich als reichen Idioten auszugeben, der ein leichtes Diebstahlopfer ist. Ben wurde kurzer Hand von zwei Ganoven verfolgt und konnte diese in einer dunklen Gasse überwältigen und Informationen über einen lokalen Schwarzmarkt bekommen. Ben und Lucardis machten sich dann auf dem Weg und konnte dort eine gute Menge an Gold für ihre Waren bekommen. Während dessen warteten Horizion und Gellea am Marktplatz und beobachteten die Umgebung. Horizon viel sofort eine hochrangige Person auf, eine mit einem Prime rang. Er ging auf den Tiefling zu und der erklärte ihm warum er hier ist, die lokale Mine ist derzeit geschlossen wegen seltsamen Erkrankungen der Arbeiter und war auf dem Weg zum Tribun um mit diesem das Vorgehen zu besprechen. Die Gruppe wollte auch zum Tribun und so machten sie sich nach Ben und Lucardis rückkehr zusammen auf dem Weg zum Tribun von Vectis."},

  {title: "Stone Garden Episode 21 - Schatten in den Minen", recap: "Dakira Peacescale, ein Tiefling und Prime der Curatio Salus, begleitet die Gruppe zum Tribun Marius Graham. Sie erfahren das dieser sich schon auf den Weg zu den Minen gemacht hat, Dakira teleportiert alle zusammen darauf hin direkt vor den Eingang der Mine. Sie trennen sich von Dakira um nach dem Tribun in den Minen zu suchen, tiefer in den Minen werden sie von Geistern attackiert und finden einen versteckten Eingang der mithilfe magischer Energie frei gelegt werden muss. Dieser Weg führt zu einer in Schatten gehüllten Dame, welche sich als jemand vom Widerstand herraus stellte. Nach einer hitzigen Diskussion verlassen diese den Untergrund über ein Schattenportal und landen etwas abseits der Mine an der Oberfläche. Sie sprinten zurück zur Mine um Dakira zur hilfe zur eilen. Dakira hat spuren eines Zaubers aufspüren können, die wohl darauf hinweisen das der Tribun verschwunden ist."},
  {title: "Stone Garden Episode 22 - Melodie eines Waldes", recap: "Nach dem die Gruppe Tribun Marius Graham nicht aufspüren konnten und dies nun strikte Imperiums Angelegenheit ist, wird sich Dakira alleine darum kümmern müssen. Die Gruppe beschließt weiter Richtung Esquillin zu reisen und müssen dafür durch ein kleines Waldgebiet. Am Waldeingang werden sie von einer Imperialen Patroullie abgefangen, weil sich in letzter Zeit Vermisstenfälle im Wald häufen. Die Gruppe betritt den Wald, einige von ihnen werden kurz zeitig von einer Melodie verzaubert und laufen wahrlos in verschiedene Richtungen, aber sie schaffen es zusammen zu bleiben. Sie treffen aus eine alte Dame, welche hier alleine in einer Hütte lebt, diese erzählt das vor kurzen seltsam aussehende Harfen erschienen sind und eine schöne Melodie durch den Wald spielen. Die Dame Donna scheint aber von der Melodie nicht beeinflusst zu werden. Sie erreichen das Ende des Waldes und kommen bald in Esquillin an."},
  {title: "Stone Garden Episode 23 - Ein neuer Herausforderer", recap: "Endlich in Esquillin angekommen, sucht Lucardis direkt das Kolosseum um dort die Statue von Edos auf zu suchen. Lucardis erhält eine Vision als sie mental mit der Statue kommuniziert und muss schnell in Richtung westen nach Portus Fractus. Zusammen beschließt die Gruppe, dass Lucardis alleine weiter reist. Ein Showkampf soll am Abend zwischen einer lokalen Legende und einem Warforged statt finden, bei dem Warforged handelt es sich um einen Bruder von Horizion, Chronos. Nach einem beeindruckenden Kampf zwischen Chronos und der Leonin Zesa of the Suncoat, welchen Zesa gewann, hört die Gruppe von einem Trunier welches am nächsten Tag statt finden soll. Bei dem Turnier kann man allerdings nur als vierer Gruppe teilnehmen kann, auf der suche nach einem weitern Gruppen Mitglied für das Turnier, kam die Leonin Zesa auf die Gruppe zu und schlug vor zusammen am Turnier teil zu nehmen"},
  {title: "Stone Garden Episode 24 - Das Turnier", recap: "Die Gruppe trifft sich am Morgen mit Zesa um sich auf das Turnier vorzubereiten. Die erste Runde des Turniers ist ein Kampf gegen mehrere Amphisbaena, zweiköpfige große Schlangenwesen. In der zweiten Runde werden die Arenen zu Unterwasserpools umgebaut, dort müssen die Gruppen Gegenstände aus dem Wasser bergen welche von einem Wasser Elementar bewacht werden. Gruppen welche es in die dritte Runde geschafft haben, müssen auf fliegenden Plattformen gegen einen Hasen kämpfen der in eine Drachen gepolymorphed wurde. Zesas Gruppe ist die einzige Gruppe welche erfolgreich alle 3 Runden bestanden hat und damit die Sieger des Turniers. Als Preis erhalten sie ein besonderes Trinkhorn, Gold und einen Erinnerungsplatz in der Doxa, einem Ort in dem Gewinner des Kolosseums verewigt werden."},
  {title: "Stone Garden Episode 25 - Zeit auf Null", recap: "Nach dem gewonnen Turnier treffen sich Zesa, die Gruppe und Chronos in der Lavahöhle, einer Taverne in der nähe des Kolosseums. Nach einem herzlichen Wiedersehen zwsichen den Warforged Brüdern, möchte sich Zesa der Gruppe anschließen um neue Herausforderungen entgegen zu stellen. Chronos erzählt von einer Entität, Null, ein Tempel von ihm soll sich in dem Wald nördlich von Esquillin befinden. Er hofft dort eine Schriftrolle zu finden, die ihm hilft seinen gefallenen Freunden zu helfen. Die neue Gruppe beschließt am nächsten Morgen sich auf den Weg zu machen Nulls Versteck zu finden."},
  {title: "Stone Garden Episode 26 - Der Mitternachtsbaum", recap: "Auf der suche nach Nulls Baum, bemerken sie das ein bestimmtes Waldgebiet bereits vom Widerstand eingenommen wurde. Die Gruppe schleicht sich in das Gebiet hinein und überfällt ein kleinen Außenposten und verhöhrt einige Mitglieder des Widerstandes. Sie erfahren das der Baum bereits von ihnen eingenommen wurde und dies momentan eine kleine Basis für sie ist. "},
];
