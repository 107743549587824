export type markerInfo = {
  name: string;
  ruler?: string;
  population?: string;
  orga?: string;
  ethnicity?: string;
  pointsOfInterest?: string[];
};

export const markerInfos: markerInfo[] = [
  {
    name: "Palatin",
    ruler: "Kaiser Kain Astalos",
    population: "2.5 Millionen",
    ethnicity: "Mixed",
  },
  { name: "Stone Garden", population: "8" },
  {
    name: "Aramore",
    ruler: "",
    population: "1.5 Millionen",
    orga: "Institution: Insomnis Vigilis",
    ethnicity: "Menschen, Dragonborn, Mixed",
    pointsOfInterest: ["Insomnis Vigilis HQ", "Arcana Viertel"],
  },
  {
    name: "Vectis",
    ruler: "Tribun Marius Graham",
    population: "70k",
    orga: "Institution: Divitia Maxima",
  },
  {
    name: "Esquillin",
    ruler: "Tribun Roman Noro",
    population: "750k",
    orga: "Institution: Defensio Civitas",
    pointsOfInterest: ["Colosseum", "Defensio Civitas HQ"],
  },
  {
    name: "Erastin",
    ruler: "Tribun Cynthia Penda",
    population: "200k",
    orga: "Institution: Aternum Scorpio",
  },
  {
    name: "Salva",
    ruler: "",
    population: "20k",
    orga: "Institution: Insomnis Vigilis",
  },
  {
    name: "Capsan",
    ruler: "Tribun Kathrin Laxus",
    population: "40k",
    orga: "Institution: Defensio Civitas",
    pointsOfInterest: ["4 Magier Türme", "Nebelfluss"],
  },
  {
    name: "Aventin",
    ruler: "",
    population: "600k",
    orga: "Institution: Curatio Salus",
    pointsOfInterest: ["Curatio Salus HQ"],
  },
  {
    name: "Vetera",
    ruler: "",
    population: "50k",
    orga: "Institution: Aternum Scorpio",
  },
  {
    name: "Vradan",
    ruler: "",
    population: "250k",
    orga: "Institution: Aternum Scorpio",
    pointsOfInterest: ["Aternum Scorpio HQ"],
  },
  {
    name: "Nidum",
    ruler: "",
    population: "90k",
    orga: "Institution: Defensio Civitas",
  },
  {
    name: "Portus Fractus",
    ruler: "",
    population: "200k",
    orga: "Institution: Saevus Aquidus",
    pointsOfInterest: ["Saevus Aquidus HQ"],
  },
  {
    name: "Warcrest",
    ruler: "",
    population: "350k",
    orga: "Institution: Divitia Maxima",
    pointsOfInterest: ["Divitia Maxima HQ", "Imperiale Schatzkammer"],
  },
  {
    name: "Triden",
    ruler: "",
    population: "35k",
    orga: "Institution: Sangius Gladius",
    ethnicity: "Menschen, Zentauren",
  },
  {
    name: "Divio",
    ruler: "",
    population: "25k",
    orga: "Institution: Sangius Gladius",
    ethnicity: "Menschen, Zwerge",
    pointsOfInterest: ["Titanen Mine"],
  },
  {
    name: "Zelah",
    ruler: "",
    population: "10k",
    orga: "Institution: Defensio Civitas",
  },
  {
    name: "Tregaron",
    ruler: "Nakzul Muskelreißer",
    population: "5k",
    ethnicity: "Orcs",
  },
  {
    name: "Sinocard",
    ruler: "Xafirex",
    population: "400k",
    orga: "Drachenwacht",
    ethnicity: "Dragonborn",
  },
  {
    name: "Alephia",
    ruler: "Amon-Ra",
    population: "1 Millionen",
    ethnicity: "Aarakocra, Genasi",
  },
  {
    name: "Aston",
    ruler: "",
    population: "N/A",
    ethnicity: "Elfen",
    pointsOfInterest: ["Fey Bogen"],
  },
  { name: "Padus", ethnicity: "Elfen", population: "10k" },
  { name: "Torrine", ethnicity: "Elfen, Mixed", population: "250k" },
  { name: "Aurasio", ethnicity: "Mixed", population: "50k" },
  {
    name: "Salona",
    orga: "Institution: Zirkel des Feuers",
    population: "4,5 Millionen",
    ethnicity: "Mixed",
    pointsOfInterest: ["Geschmiedete Stadt"],
  },
  { name: "Aesis", population: "66k" },
  { name: "Raikan", population: "15k" },
  {
    name: "Solime",
    ethnicity: "Halb-Elfen, Menschen, Gnome",
    population: "450k",
    ruler: "Jayce Certos",
    pointsOfInterest: ["Bardic Glamour College"],
  },
  { name: "Veii", population: "2k" },
  { name: "Portus Fortuna", population: "100k" },
  { name: "Portus Zhaita", population: "50k" },
  { name: "Tarentum", population: "10k" },
  { name: "Portus Almus", population: "7k" },
  { name: "Khrain", population: "5k" },
  { name: "Brivas", ruler: "Familie Cergos", population: "150k" },
  { name: "Genava", ruler: "Familie Dardragon", population: "100k" },
  { name: "Portus Regnum", population: "20k" },
  {
    name: "Lunsar",
    ethnicity: "Goliaths",
    ruler: "Samuel Dragan Darren Thundres",
    population: "70k",
    pointsOfInterest: ["Sturm Riesen Thron"],
  },
  {
    name: "Portus Sicia",
    orga: "Institution: Saevus Aquidus",
    population: "15k",
  },
  { name: "Avennio", population: "22k" },
  {
    name: "Garthram",
    population: "65k",
    orga: "Institution: Sangius Gladius",
    ethnicity: "Gnome, Loxodon, Menschen, Warforged, Zwerge,",
    pointsOfInterest: ["Sangius Gladius HQ", "Imperiale Schmiede"],
  },
  { name: "Portus Augus", orga: "Sangius Gladius", population: "24k" },
  {
    name: "Falkirk",
    orga: "Sangius Gladius",
    population: "200k",
    pointsOfInterest: ["Militär Grundausbildung", "Warforged Schmiede"],
  },
  { name: "Portus Veli", orga: "Sangius Gladius", population: "20k" },
  { name: "Portus Cale", population: "10k" },
  { name: "Helios Novum", population: "5k", ethnicity: "Leonin" },
  { name: "Portus Basil", population: "15k" },
];
