export const palatinBorderData:number [] [] = [
    [1195,-5470],
    [950,-5610],
    [820,-5620],
    [650,-5540],
    [600,-5550],
    [450,-5580],
    [300,-5470],
    [120,-5390],
    [120,-5350],
    [60,-5360],
    [0,-5270],
    [-60,-5270],
    [-150,-5170],
    [-250,-5090],
    [-260,-4950],
    [-300,-4850],
    [-320,-4750],
    [-350,-4650],
    [-420,-4550],
    [-480,-4450],
    [-500,-4350],
    [-500,-4280],
    [-460,-4260],
    [-460,-4195],
    [-510,-4150],
    [-510,-4000],
    [-500,-3860],
    [-600,-3810],
    [-650,-3790],
    [-790,-3710],
    [-890,-3700],
    [-900,-3600],
    [-860,-3500],
    [-890,-3450],
    [-990,-3450],
    [-1070,-3410],
    [-1130,-3420],
    [-1180,-3350],
    [-1130,-3280],
    [-1000,-3300],
    [-920,-3180],
    [-920,-3140],
    [-800,-3130],
    [-620,-3140],
    [-410,-3000],
    [-450,-2950],
    [-550,-2920],
    [-600,-2970],
    [-620,-2940],
    [-660,-2800],
    [-710,-2780],
    [-750,-2600],
    [-830,-2500],
    [-950,-2540],
    [-1050,-2640],
    [-1140,-2720],
    [-1300,-2600],
    [-1410,-2600],
    [-1430,-2560],
    [-1375,-2550],
    [-1470,-2460],
    [-1530,-2380],
    [-1650,-2390],
    [-1750,-2360],
    [-1830,-2320],
    [-1930,-2280],
    [-2020,-2280],
    [-2100,-2230],
    [-2200,-2130],
    [-2250,-2110],
    [-2260,-2040],
    [-2320,-2020],
    [-2270,-1890],
    [-2350,-1770],
    [-2350,-1670],
    [-2330,-1610],
    [-2400,-1590],
    [-2330,-1550],
    [-2370,-1440],
    [-2300,-1460],
    [-2230,-1340],
    [-2295,-1240],
    [-2350,-1100],
    [-2400,-1000],
    [-2500,-960],
    [-2620,-980],
    [-2610,-870],
    [-2680,-750],
    [-2680,-660],
    [-2780,-620],
    [-2880,-600],
    [-2920,-540],
    [-2920,-350],
    [-2850,-300],
    [-2890,-210],
    [-2980,-180],
    [-3030,-10],
    [-3030,480],
    [-2900,550],
    [-2800,650],
    [-2700,730],
    [-2600,870],
    [-2450,900],
    [-2280,750],
    [-2180,550],
    [-2000,350],
    [-1800,150],
    [-1600,-50],
    [-1500,-200],
    [-1500,-350],
    [-1400,-550],
    [-1250,-650],
    [-1050,-550],
    [-950,-350],
    [-950,-150],
    [-950,100],
    [-950,300],
    [-800,450],
    [-650,600],
    [-400,700],
    [-250,700],
    [-100,500],
    [0,300],
    [100,100],
    [200,-100],
    [300,-300],
    [400,-400],
    [600,-500],
    [800,-550],
    [1000,-550],
    [1200,-550],
    [1400,-500],
    [1600,-650],
    [1800,-950],
    [2000,-1150],
    [2200,-1250],
    [2300,-1250],
    [2340,-1480],
    [2420,-1560],
    [2400,-1650],
    [2300,-1670],
    [2200,-1700],
    [2100,-1780],
    [1970,-1830],
    [1850,-1610],
    [1750,-1550],
    [1650,-1460],
    [1500,-1570],
    [1420,-1670],
    [1360,-1780],
    [1300,-1860],
    [1280,-1910],
    [1350,-1990],
    [1320,-2080],
    [1220,-2180],
    [1120,-2250],
    [1020,-2340],
    [980,-2340],
    [900,-2400],
    [860,-2500],
    [840,-2600],
    [830,-2700],
    [830,-2800],
    [830,-2900],
    [780,-3000],
    [810,-3100],
    [840,-3190],
    [790,-3300],
    [750,-3470],
    [700,-3580],
    [600,-3640],
    [500,-3780],
    [400,-3950],
    [430,-4100],
    [480,-4200], 
    [490,-4400],
    [510,-4500],
    [520,-4550],
    [600,-4585],
    [720,-4700],
    [770,-4830],
    [850,-4820],
    [820,-4890],
    [820,-4950],
    [800,-5050],
    [850,-5150],
    [1010,-5120],
    [1140,-5150],
    [1180,-5220],
    [1150,-5300],
    [1140,-5370],
    [1180,-5400],

];