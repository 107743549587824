export const astonBorderData: number[][] = [
  [-1150, 3120],
  [-1150, 3300],
  [-1200, 3350],
  [-1250, 3400],
  [-1300, 3400],
  [-1340, 3400],
  [-1320, 3480],
  [-1280, 3550],
  [-1280, 3650],
  [-1270, 3700],
  [-1240, 3750],
  [-1260, 3800],
  [-1275, 3850],
  [-1315, 3900],
  [-1280, 3950],
  [-1270, 4050],
  [-1270, 4100],
  [-1255, 4150],
  [-1250, 4200],
  [-1250, 4240],
  [-1200, 4280],
  [-1150, 4355],
  [-1160, 4400],
  [-1130, 4430],
  [-1100, 4460],
  [-1080, 4525],
  [-1065, 4560],
  [-1085, 4580],
  [-1085, 4610],
  [-1100, 4650],
  [-1100, 4690],
  [-1100, 4740],
  [-1125, 4780],
  [-1105, 4810],
  [-1150, 4860],
  [-1210, 4915],
  [-1185, 4965],
  [-1195, 5030],
  [-1095, 5055],
  [-1005, 5050],
  [-955, 5035],
  [-905, 5030],
  [-870, 5035],
  [-860, 5020],
  [-800, 5025],
  [-755, 5040],
  [-720, 5030],
  [-657, 5090],
  [-600, 5140],
  [-585, 5120],
  [-530, 5150],
  [-480, 5170],
  [-430, 5205],
  [-390, 5240],
  [-360, 5240],
  [-350, 5260],
  [-330, 5265],
  [-300, 5295],
  [-280, 5295],
  [-265, 5255],
  [-230, 5235],
  [-225, 5218],
  [-260, 5200],
  [-280, 5160],
  [-230, 5085],
  [-205, 5065],
  [-215, 5045],
  [-230, 5035],
  [-230, 5025],
  [-250, 5000],
  [-280, 4990],
  [-280, 4970],
  [-265, 4960],
  [-270, 4925],
  [-305, 4910],
  [-330, 4905],
  [-355, 4905],
  [-375, 4885],
  [-400, 4880],
  [-450, 4880],
  [-330, 4750],
  [-310, 4720],
  [-285, 4720],
  [-280, 4690],
  [-295, 4675],
  [-295, 4645],
  [-260, 4550],
  [-240, 4530],
  [-220, 4515],
  [-230, 4505],
  [-160, 4490],
  [-130, 4460],
  [-90, 4460],
  [-70, 4510],
  [5, 4400],
  [-10, 4375],
  [40, 4360],
  [100, 4335],
  [150, 4290],
  [200, 4260],
  [280, 4255],
  [320, 4195],
  [355, 4195],
  [400, 4165],
  [450, 4130],
  [500, 4090],
  [515, 4125],
  [540, 4175],
  [570, 4220],
  [585, 4220],
  [610, 4195],
  [635, 4165],
  [660, 4165],
  [735, 4175],
  [735, 3670],
  [720, 3640],
  [690, 3640],
  [690, 3680],
  [650, 3690],
  [620, 3700],
  [590, 3700],
  [540, 3730],
  [500, 3780],
  [470, 3815],
  [440, 3835],
  [400, 3835],
  [350, 3850],
  [300, 3850],
  [250, 3850],
  [180, 3850],
  [150, 3800],
  [100, 3730],
  [100, 3705],
  [130, 3675],
  [145, 3460],
  [20, 3365],
  [-85, 3315],
  [-185, 3315],
  [-220, 3315],
  [-250, 3240],
  [-335, 3130],
  [-400, 3030],
  [-500, 3030],
  [-600, 3030],
  [-700, 3030],
  [-800, 3030],
  [-850, 3130],
  [-900, 3200],
  [-1000, 3200],
  [-1150, 3120],
];
